<template>
  <v-dialog
    v-model="dialogAddPlanoContas"
    @click:outside="$emit('update:dialogAddPlanoContas', false)"
    @keydown.esc="$emit('update:dialogAddPlanoContas', false)"
    width="650px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.novo") + " " + $tc("global.planocontas")
            : $tc("global.atualizar") + " " + $tc("global.planocontas")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddPlanoContas', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-container fluid class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="planocontas.descricao"
                :label="$tc('global.descricao')"
                dense
                :readonly="edit ? !PPlanoContaEditar : false"
                outlined
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-btn
                v-if="!selectedPai"
                :disabled="edit ? !PPlanoContaEditar : false"
                color="tocs_gray_2"
                class="white--text"
                @click="dialogSelectPlanoContas = true"
              >
                <v-icon left>mdi-file-document-outline</v-icon>
                Plano de Contas
              </v-btn>


              
              <div class="d-flex">
                <v-combobox
                  v-if="selectedPai"
                  v-model="selectedPai"
                  :label="$tc('global.planocontas')"
                  dense
                  :readonly="edit ? !PPlanoContaEditar : false"
                  outlined
                  item-text="descricao"
                  item-value="id"
                ></v-combobox>
                <v-btn
                  v-if="edit ? PPlanoContaEditar : true && selectedPai"
                  text
                  fab
                  small
                  @click="dialogSelectPlanoContas = true"
                >
                  <v-icon size="18">mdi-pencil</v-icon>
                </v-btn>
              </div>
              <DialogSelectPlanoContas
                v-if="dialogSelectPlanoContas"
                :dialogSelectPlanoContas.sync="dialogSelectPlanoContas"
                @select-plano-contas="selectPlanoConta"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="planocontas.tipo"
                :items="tipos"
                outlined
                dense
                :disabled="edit ? !PPlanoContaEditar : false && !selectedPai"
                hide-details
                label="tipo"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="pb-6">
              <v-card
                v-if="edit"
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Status
                  <span class="pl-1" v-if="planocontas.status === 1">
                    {{ $tc("global.ativo") }}
                  </span>
                  <span class="pl-1" v-else>{{ $tc("global.inativo") }}</span>
                </v-subheader>
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  :disabled="edit ? !PPlanoContaEditar : false"
                  v-model="planocontas.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogAddPlanoContas', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          v-if="edit ? PPlanoContaEditar : true"
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createPlanoContas() : updatePlanoContas()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  fetchPlanosContasTree,
  postPlanoContas,
  putPlanoContas,
} from "@/api/plano-contas/plano_contas.js";
import { mapGetters } from "vuex";
export default {
  name: "dialogAddPlanoContas",

  props: {
    dialogAddPlanoContas: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      planocontas: {
        status: 1,
        tipo: "A",
      },
      planocontas_original: {},
      loading: true,
      validForm: true,
      formRules: [inputRequired],
      selectedPlanoConta: null,
      selectedPai: null,
      planoscontas_2: [],
      tipos: ["A", "S"],
      dialogSelectPlanoContas: false,
    };
  },

  components: {
    DialogSelectPlanoContas: () => import("./DialogSelectPlanoContas.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PPlanoContaEditar() {
      return this.getAccess("PlanoContas", "editar");
    },
  },

  watch: {},

  methods: {
    getPlanosContas() {
      fetchPlanosContasTree().then((response) => {
        let planocontaSelecionado = response.find(
          (post) => post.id == this.planocontas.id_pai
        );
        this.selectedPai = planocontaSelecionado;
        this.planoscontas_2 = response.filter(
          (post) => post.id != this.planocontas.id
        );
      });
    },
    createPlanoContas() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        postPlanoContas(this.planocontas)
          .then(() => {
            this.$emit("fetch-planocontas");
            this.$emit("update:dialogAddPlanoContas", false);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error("Plano de contas ja existe!");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updatePlanoContas() {
      if (
        JSON.stringify(this.planocontas) ===
        JSON.stringify(this.planocontas_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let planocontas = {};

        planocontas.id_pai = this.planocontas.id_pai;
        planocontas.descricao = this.planocontas.descricao;
        planocontas.status = this.planocontas.status;
        planocontas.tipo = this.planocontas.tipo;

        putPlanoContas(this.planocontas.id, planocontas)
          .then(() => {
            this.planocontas_original = { ...this.planocontas };
            this.$emit("fetch-planocontas");
            this.$emit("update:dialogAddPlanoContas", false);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error("Plano de contas ja existe!");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    selectPlanoConta(plano_selecionado) {
      this.selectedPai = plano_selecionado;
      if (this.selectedPai) {
        this.planocontas.id_pai = this.selectedPai.id;
      }
    },
  },

  mounted() {
    this.getPlanosContas();
    if (this.edit) {
      this.planocontas = { ...this.item };
      this.planocontas_original = { ...this.item };
    }
  },
};
</script>

<style></style>
